import store from "@/store";

const UserService = {
    /**
     * If code is a string, just one permission, if it is an array than it's an OR between permissions.
     * @param code
     * @returns {boolean}
     */
    hasPermission: (code) => {
        if (!code) {
            return true;
        }
        let user = store.getters.currentUser;
        // console.log('user', user);
        if (!user || !user.permissions) {
            return false;
        }
        // if (code == 'super'){
        //     return false;
        // }
        if (code == 'super' && !UserService.isSuperDebugUser()) { // global permission
            return false;
        }
        if (code == 'adminextcrm' && !UserService.allowReadonlyExternalCrm() && !UserService.isSuperDebugUser()) { // global permission
            return false;
        }
        if (code == 'adminextcrmwrite' && !UserService.isSuperDebugUser()) { // global permission
            return false;
        }
        if (code == 'adminorg' && !UserService.isSuperDebugUser() && !user.is_organization_admin) { // global permission
            return false;
        }

        // Blocca temporaneamente tutti da modifiche contratti
        const specialToBlock = ['contract.edit', 'contract.delete', 'contract.create'];
        if (specialToBlock.includes(code) && !UserService.isSuperDebugUser()) {
            return false;
        }
        //

        if (user.permissions.includes("*")) { // global permission
            return true;
        }
        if (Array.isArray(code)) {
            return user.permissions.filter(x => code.includes(x)).length > 0;
        } else {
            return user.permissions.includes(code);
        }
    },
    isSuperDebugUser: () => {
        return store.getters.currentUser.email == 'nicolamti@gmail.com' ||
        store.getters.currentUser.email == 'marco.palama@soloweb.io' ||
        store.getters.currentUser.email == 'andrea.gasloli@soloweb.io' ||
        store.getters.currentUser.email == 'fabrizio.dammassa@gmail.com';
    },
    allowReadonlyExternalCrm: () => {
        let allowed = ['spanti@estraspa.it','lcecconi@estraspa.it','ddemartino@estraspa.it'];
        return allowed.includes(store.getters.currentUser.email);
    }
};

export default UserService;
