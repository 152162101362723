import './assets/js/whitelabel';

import Vue from "vue";
// import './../public/assets/js/output-es5';
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ApiService from "./common/api.service";
import UserService from "./common/user.service";
import MockService from "./common/mock/mock.service";
import { VERIFY_AUTH } from "./store/auth.module";
import Component from "vue-class-component";
import VCalendar from 'v-calendar';

import Rollbar from 'rollbar';

// Set the Rollbar instance in the Vue prototype
// before creating the first Vue instance.
// This ensures it is available in the same way for every
// instance in your app.
class CustomDebug extends Rollbar{}
const enableTracker = !(location.hostname === "localhost" || location.hostname === "127.0.0.1");
const codeVersion = process.env.VUE_APP_BUILD_TIMESTAMP;
// const enableTracker = true;
Vue.prototype.$customdebugtracker = new CustomDebug({
  accessToken: 'b8ca10bb2df2458da2f6ede0739e1d87',
  captureUncaught: true,
  captureUnhandledRejections: true,
  enabled: enableTracker,
    payload: {
        // environment: window.__env?window.__env:'TESTING',
        environment: DEBUGENABLED?'TESTING':'PRODUCTION',
        client: {
            javascript: {
                source_map_enabled: true, // true by default

                // -- Add this into your configuration ---
                code_version: codeVersion,
                // ---------------------------------------

                // Optionally have Rollbar guess which frames the error was
                // thrown from when the browser does not provide line
                // and column numbers.
                guess_uncaught_frames: true
            }
        }
    }
});

// If you have already set up a global error handler,
// just add `vm.$rollbar.error(err)` to the top of it.
// If not, this simple example will preserve the app’s existing
// behavior while also reporting uncaught errors to Rollbar.
if(enableTracker) {
  Vue.config.errorHandler = (err, vm, info) => {
    vm.$customdebugtracker.error(err);
    throw err; // rethrow
  };
}

//this.$customdebug.info('Hello world.');


Vue.config.productionTip = false;

// Global 3rd party plugins
import "bootstrap";
import "popper.js";
import "tooltip.js";
import "perfect-scrollbar";

import './assets/js/form/utils';

// Vue 3rd party plugins
import i18n from "./common/plugins/vue-i18n";
import vuetify from "./common/plugins/vuetify";
import "./common/plugins/bootstrap-vue";
import "./common/plugins/perfect-scrollbar";
import "./common/plugins/highlight-js";
import "./common/plugins/general-custom";
import "./common/directives/permission";
import "./common/filters/date";
import "./common/components/clickconfirm";
import "@babel/polyfill";
import "@mdi/font/css/materialdesignicons.css";
//import 'formiojs/dist/formio.form.css';
import 'formiojs/dist/formio.full.css';
import {DEBUGENABLED} from "./common/config";
//import 'formiojs/dist/formio.builder.css'; // Todo not really needed for everybody


// API service init
ApiService.init();

// Remove this to disable mock API
// MockService.init();

// Ensure we checked auth before each page load.
router.beforeEach((to, from, next) => {
  Promise.all([store.dispatch(VERIFY_AUTH)]).then(next);

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});
Vue.config.ignoredElements = [
  // 'app-customform'
];
//
// Component.registerHooks([
//   'beforeRouteEnter',
//   'beforeRouteLeave',
//   'beforeRouteUpdate',
// ]);

// check if needs to change password
router.beforeEach((to, from, next) => {
    var currentUser = store.getters["currentUser"];
    if(currentUser.password_expired){
        if(!['force-change-password'].includes(to.name)){
            next({ name: 'force-change-password' });
            return;
        }
    }
    next();
});






Vue.config.devtools = true; // TODO PROD REMOVE

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: h => h(App)
}).$mount("#app");
Vue.use(VCalendar, {
    componentPrefix: 'vc',  // Use <vc-calendar /> instead of <v-calendar />
});
// window.__VUE_DEVTOOLS_GLOBAL_HOOK__.Vue = app.constructor; // TODO PROD REMOVE

