import Vue from "vue";
import $ from 'jquery';
const GeneralCustom = {
    install(Vue, options) {
// 1. add global method or property
    Vue.customNotify = function(title,message,variant){
        //https://bootstrap-vue.org/docs/components/toast
        // console.log(title,message,variant);
        const vm = new Vue();
        if(!message){
            message = title;
            title = null;
        }
        vm.$bvToast.toast(message, {
            title: title,
            variant:variant,
        });
    };
    Vue.customNotifySuccess = function(title,message){
        Vue.customNotify(title,message,'success');
    };
    Vue.customNotifyError = function(title,message){
        Vue.customNotify(title,message,'danger');
    };


    Vue.copyToClipboard = function(text) {
            var $temp = $("<input>");
            $("body").append($temp);
            $temp.val(text).select();
            document.execCommand("copy");
            $temp.remove();
        }

// // 2. add a global asset
//     Vue.directive('my-directive', {})
//
// // 3. add an instance method
//     Vue.prototype.$myMethod = ...

    }
};
 Vue.use(GeneralCustom);

export default GeneralCustom;