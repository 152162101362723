import Vue from "vue";
import moment from 'moment';
var MockAdapter = require("axios-mock-adapter");

// mock testing user accounts
const users = [
  {
    email: "admin@demo.com",
    name: "Andrea",
    password: "demo",
    token: "mgfi5juf74j",
    permissions:["*","contract.view","contract.log.view"]
  },
  {
    email: "admin2@demo.com",
    name: "B",
    password: "demo",
    token: "fgj8fjdfk43"
  }
];

const MockService = {
  init() {
    this.deletedDocument = false;
    var that = this;
    // this sets the mock adapter on the default instance
    var mock = new MockAdapter(Vue.axios);

    // mock login request
    /*mock.onPost("/login").reply(data => {
      const credential = JSON.parse(data.data);
      const found = users.find(user => {
        return (
          credential.email === user.email &&
          credential.password === user.password
        );
      });
      if (found) {
        return [200, found];
      }
      return [404, { errors: ["The login detail is incorrect"] }];
    });*/

    // mock to verify authentication
    mock.onGet(/\/verify\/?/).reply(data => {
      const token = data.headers.Authorization.replace("Token ", "");
      if (token !== "undefined") {
        const found = users.find(user => {
          return token === user.token;
        });
        return [200, found];
      }
      return [401, { errors: ["Invalid authentication"] }];
    });

    // mock delete
    mock.onDelete(/\/contract\/?/).reply(data => {
      const token = data.headers.Authorization.replace("Token ", "");
      if (token !== "undefined") {
        that.deletedDocument = true;
        return [200, true];
      }
      return [401, { errors: ["Invalid authentication"] }];
    });

    // mock to get a list of something
    mock.onGet(/\/contract\/?/).reply(data => {
      return [200, {
        config:{
          label:'xyz',
        },
        results:that.deletedDocument?[
          {
            'id':1,
            'label':'xyz',
            'enabled':true,
            created_at:moment('2020-04-15T09:45Z'),
            updated_at:moment('2020-04-15T09:45Z')
          },
        ]:[
          {
            'id':1,
            'label':'xyz',
            'enabled':true,
            created_at:moment('2020-04-15T09:45Z'),
            updated_at:moment('2020-04-15T09:45Z')
          },
          {
            'id':3,
            'label':'xyz',
            'enabled':true,
            created_at:moment('2020-04-14T09:45Z'),
            updated_at:moment('2020-04-14T09:45Z')
          }
        ]
      }];
    });
  }
};

export default MockService;
