import Vue from "vue";
import ClickConfirm from 'click-confirm/dist/click-confirm';
//Vue.component('clickConfirm', ClickConfirm);
Vue.component('clickConfirm', {
    extends: ClickConfirm,
    props: {
        messages: {
            type: Object,
            default: function(){return {
                title: 'Confermare l\'operazione?',
                yes: 'Sì',
                no: 'No'
            };}
        }
    }
});