import Vue from "vue";
import UserService from "@/common/user.service";

Vue.directive('permission', (el, binding, vnode) => {
    let hasPermission = UserService.hasPermission(binding.value);
    // console.log('hasPermission',hasPermission,binding.value);
    // if (!isUserGranted(binding.value)) {
    if (!hasPermission) {
        // replace HTMLElement with comment node
        const comment = document.createComment(' ');
        Object.defineProperty(comment, 'setAttribute', {
            value: () => undefined,
        });
        vnode.elm = comment;
        vnode.text = ' ';
        vnode.isComment = true;
        vnode.context = undefined;
        vnode.tag = undefined;
        vnode.data.directives = undefined;

        if (vnode.componentInstance) {
            vnode.componentInstance.$el = comment;
        }

        if (el.parentNode) {
            el.parentNode.replaceChild(comment, el);
        }
    }
});
