function initWhitelabel() {
    window.whitelabel = {};
    var urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has('appTitle')) {
        window.whitelabel.appTitle = urlParams.get('appTitle');
    } else {
        window.whitelabel.appTitle = "FastSign";
    }
    if (urlParams.has('appBackgroundNumber')) {
        window.whitelabel.appBackground = process.env.BASE_URL + "assets/media/bg/bg-"+parseInt(urlParams.get('appBackgroundNumber'))+".jpg";
    } else {
        window.whitelabel.appBackground = process.env.BASE_URL + "assets/media/bg/bg-4.jpg";
    }

    window.whitelabel.getAppTitle= function() {
        return window.whitelabel.appTitle;
    };
    window.whitelabel.getAppBackground= function() {
        return window.whitelabel.appBackground;
    };

}

initWhitelabel();
document.title = window.whitelabel.getAppTitle();