import moment from 'moment';
import Vue from "vue";
Vue.filter('formatDate', function(value) {
    if (value) {
        if(typeof(value)=='string'){
            value = moment(String(value));
        }
        //console.log(value);
        return value.format('DD/MM/YYYY HH:mm');
    }
});
